<template>
  <v-app>
    <div class="app-container">
      <div class="app-header">
        <v-btn @click="goHome" variant="text" color="blue-grey-darken-4" size="large">HOME</v-btn>
        <selectLocale></selectLocale>
      </div>
      <h2 :style="{'color': font_color, 'text-align': 'center'}">{{ android_check }}</h2>
      <p>{{ msg }}</p>
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from "vue-router"
import selectLocale from './component/selectLocale.vue';

import { useCartStore } from "@/store/cartStore"

const router = useRouter()
const store = useCartStore()

function goHome() {
  store.resetMyCart()
  router.push('/')
}

/* 안드로이드 앱 하이브리드 통신 */
const android_check = ref('')
const font_color = ref('blue')
declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
      AndroidApp: any; 
      onNativeResponse: any;
    }
}
window.AndroidApp = window.AndroidApp || undefined;
window.onNativeResponse = onNativeResponse;

if (window.AndroidApp) {
  android_check.value = '안드로이드 통신 가능 ^^'
} else {
  android_check.value = '안드로이드 통신 불가능!'
  font_color.value = 'red'
}

const msg = ref('')
async function onNativeResponse(msg_string: string) {
  msg.value = JSON.parse(msg_string)
}

</script>

<style>
.app-header {
  background-color: white; display: flex; justify-content: space-between; align-items: center;
  padding: 10px 20px;
}
</style>