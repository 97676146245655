import axios from 'axios'
import { API_URL } from './url'

axios.defaults.baseURL = API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		return {... error.response}
	},
)
