let API_URL = 'https://dev-test.knowck.com/api'
let FILE_URL = 'https://dev-test.knowck.com/file'
const MOCK_USE_YN = process.env.VUE_APP_MOCK_USE_YN


if (process.env.NODE_ENV === 'development' && !MOCK_USE_YN) {
  // 개발용
  API_URL = 'http://192.168.0.152:8131/api'
  FILE_URL = 'http://192.168.0.152:8131/file'
} else if (process.env.NODE_ENV === 'development' && MOCK_USE_YN) {
  API_URL = 'http://mock.test.com/api'
} else if (location.hostname.startsWith('192.168.')) {
  // 내부망 테스트용
}

export { API_URL, FILE_URL }
