import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { TicketFee } from "@/model/const"

export const useCartStore = defineStore('cart', () => {
  const myCartList = ref([] as TicketFee[])
  const installment = ref(0)

  function setMyCartList(arr: TicketFee[]) {
    myCartList.value = arr.filter((item) =>
      item.ea > 0
    )
  }

  function resetInstallment() {
    installment.value = 0
  }

  function resetMyCart() {
    setMyCartList([] as TicketFee[])
  }

  const totalFee = computed(() => {
    let total = 0
    for (let i = 0; i < myCartList.value.length; i++) {
      total += myCartList.value[i].fee * myCartList.value[i].ea
    }
    return total
  })

  return {
    myCartList,
    setMyCartList,
    resetMyCart,
    totalFee,
    installment,
    resetInstallment
  }
})