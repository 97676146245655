import { createApp } from 'vue'
import { createI18n, useI18n, type I18nOptions } from 'vue-i18n'
import { createPinia } from 'pinia'
import App from './App.vue'
import router   from "./router"
import './api'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { en as vuetify_en, ko as vuetify_ko } from 'vuetify/locale'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'

import '@/asset/css/index.css'
import '@/asset/css/common.scss'
import '@mdi/font/css/materialdesignicons.css'



async function prepareApp() {
  // if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_MOCK_USE_YN) {
  if (process.env.VUE_APP_MOCK_USE_YN) {
    const { worker } = await import('./mock/browser')
    return worker.start({
      onUnhandledRequest: 'bypass'
    })
  }

  return Promise.resolve()
}

function loadLocaleMessages () {
  const locales = require.context('./locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: Record<string, any> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale: string = matched[1]

      messages[locale] = locales(key)
      if (locale == 'ko')
        messages[locale]['$vuetify'] = vuetify_ko
      else
        messages[locale]['$vuetify'] = vuetify_en
      // import('vee-validate/dist/locale/' + locale + '.json').then((json) => {
      //   messages[locale].fields = json.fields
      //   messages[locale].validation = json.messages
      // })
    }
  })

  return messages
}

const options: I18nOptions = {
  legacy: false,
  locale: 'ko',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  datetimeFormats: {
    'ko': {
      short: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      }
    }
  },
  numberFormats: {
    'ko': {
      currency: {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'WON'
      }
    }
  }
}

const i18n = createI18n<false, typeof options>(options)

const pinia = createPinia()

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  icons:  {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
})

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(pinia)
app.use(vuetify)

prepareApp().then(() => {
  app.mount('#app')
})