import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: "/", name: "home", component: () => import("../view/mainHome.vue") },
  { path: '/ticketSearch', name: 'ticketSearch', component: () => import("../view/ticketSearch.vue") },
  { path: '/ticketSelect', name: 'ticketSelect', component: () => import("../view/ticketSelect.vue") },
  { path: '/paymentInfo', name: 'paymentInfo', component: () => import("../view/paymentInfo.vue") },
]

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "production" ? "/" : "/"),
  routes
})

export default router