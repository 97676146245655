<template>
  <div class="locale-changer">
    <v-btn color="blue-grey-darken-4" :variant="$i18n.locale=='ko' ? 'flat' : 'outlined'" @click="changeLocale('ko')">한국어</v-btn>
    <v-btn color="blue-grey-darken-4" :variant="$i18n.locale=='en' ? 'flat' : 'outlined'" @click="changeLocale('en')">English</v-btn>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
const { locale } = useI18n()

import { useLocale } from 'vuetify'
const { current } = useLocale()


const changeLocale = (locale_cd: string) => {
  locale.value = locale_cd
  current.value = locale_cd
}
</script>

<style scoped>
  .locale-changer {text-align: right;}
  
  .locale-changer .v-btn + .v-btn {margin-left: 10px;}
</style>